import { AuthContext, FireactContext, SetPageTitle } from "../lib/core";
import { Alert, Box, Button, Card, CardActions, CardHeader, Container, Grid, Paper, Typography, styled } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { Stack } from "@mui/system";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { collection, query, where, getDocs, getDoc, doc, limit } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";
import {PageSection} from "../template/TemplateElements";
import Footer from "../template/Footer";
import VimeoPlayer from "../template/VimeoPlayer";
import Hero from "./GraphQL/Hero";
import SpaceX from "./GraphQL/SpaceX";
import NFT from "./GraphQL/NFT";
//import { loadStripe } from "@stripe/stripe-js";
//import { Elements } from "@stripe/react-stripe-js";
//import CheckoutForm from "./Stripe/CheckoutForm";

//import {add} from "itdc";
import {add, subtract} from "livingwaters";

//const stripePromise = loadStripe("pk_test_51L44EYAZ8dJdmsUfoGp1AyJ5Mm6jQYpGlUDKUSPdCptoTAyVBmQ03bwhkM0jIh2sgLHy9e65MeQK6WpqAjp736SO00aV0psDqO");


const Home = ({loader}) => {

    const { authInstance, firestoreInstance, functionsInstance } = useContext(AuthContext);
    const { config } = useContext(FireactContext);
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const { authUser } = useContext(AuthContext);
    const [imageList, setImageList] = useState([]);
    
    /*
    const [clientSecret, setClientSecret] = useState("");
    
      const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };
     */

    useEffect(() => {
        setLoaded(false);
        setError(null);
        // get default permission level name
        let defaultPermission = '';
        for(var permission in config.saas.permissions){
            const value = config.saas.permissions[permission];
            if(value.default){
                defaultPermission = permission;
            }
        }

        // read images from firestore DB
        // add this route to authroutes.json to require logging in
        const imagesRef = collection(firestoreInstance, '/images');
        const imgQuery = query(imagesRef, limit(12));
        
        const storage = getStorage();
        // Create a child reference
        const imagesStorageRef = ref(storage, 'images');
        
        // all images
        Promise.all([getDocs(imgQuery)]).then(([imgSnapshot]) => {
            setImageList([]);
            imgSnapshot.forEach(record => {
                const data = record.data();
                const newImage = {
                    file: null,
                    fileName: data.filename,
                    status: "FINISH",
                    storageRef: ref(imagesStorageRef, data.filename),
                    downloadURL: data.url,
                    description: "",
                };
                //console.log("newImage ", newImage);
                setImageList((prevState) => [...prevState, newImage]);
            });
            setLoaded(true);
        }).catch(error => {
            setLoaded(true);
            setError(error.message);
        })
        
        /*
        const createCheckoutSession = httpsCallable(functionsInstance, 'fireactjsSaas-createCheckoutSession');
        //console.log('createCheckoutSession ', createCheckoutSession);
        createCheckoutSession({ items: [{ id: "xl-tshirt" }] }).then((res) => {
            //console.log('res ', res);
            setClientSecret(res.data.clientSecret)
        }).catch(error => {
            //setError(error.message);
            //setProcessing(false);
            console.log('error ', error);
        })*/
        
    },[authInstance, config.saas.permissions, firestoreInstance]);
    
    return (
        <>
            {loaded?(
                <Container maxWidth={false} disableGutters>
                    <SetPageTitle title="OpenTech+" />
                     <Box sx={{ flexGrow: 1, p: 2, width: '100%', minHeight: '100vh'}} >
                     <Grid container spacing={0}>
                           <Grid xs={12} md={8}>
                             <PageSection>
                                <VimeoPlayer embedId="16180248" />
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={4}>
                             <PageSection>
                             {imageList.length > 0 && (
                               <>
                                 <Box sx={{ width: '100%', maxHeight: 550, overflowY: 'scroll', border: 1, p: 1, borderRadius: 2, borderColor: 'lightGrey' }}>
                                    <Typography component="h5" variant="h6">
                                        Gallery
                                    </Typography>
                                    <ImageList variant="masonry" cols={3} gap={8}>
                                      {imageList.map((item) => (
                                        <ImageListItem key={item.downloadURL}>
                                          <img
                                            srcSet={`${item.downloadURL}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.downloadURL}?w=248&fit=crop&auto=format`}
                                            alt={item.title}
                                            loading="lazy"
                                          />
                                        </ImageListItem>
                                      ))}
                                    </ImageList>
                                 </Box>
                                </>
                             )}
                             </PageSection>
                           </Grid>
                           <Grid xs={12} md={12}>
                             <Hero />
                           </Grid>
                           <Grid xs={12} md={12}>
                             <Grid container spacing={0}>
                                 <Grid xs={12} md={4}>
                                     <PageSection>
                                      {add(2,6)}
                                     </PageSection>
                                 </Grid>
                                 <Grid xs={12} md={8}>
                                  <PageSection>
                                    {subtract(9,3)}
                                  </PageSection>
                                </Grid>
                             </Grid>
                           </Grid>
                         </Grid>
                    </Box>
                    <Footer />
                </Container>
            ):(
                <>{loader}</>
            )}
        </>
    )
}

export default Home;
